.atom-btn-secondary {
  &:hover,
  &:focus {
    background-color: $unogDarkGrey;
    border-color: $unogDarkGrey;
  }
}

#brand-bar {
  font-size: 13px;
}

#logos img {
  max-height: 3.5 * $spacer;
}

.clipboard-count {
  background-color: $secondary !important;
}

body {
  .navbar-dark .navbar-nav .nav-link {
    color: $white;
    &:hover, &:active, &:focus {
      color: $primary;
      background-color: $white;
    }
  }
}

body.home {
  > header {
    background-color: $white !important;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: $primary;
    &:hover, &:active, &:focus {
      color: $white;
      background-color: $primary;
    }
  }

  @include media-breakpoint-up(lg) {
    #search-box {
      max-width: initial;
    }
  }

  #search-box-results {
    transform: translate(0, 33px) !important;
  }

  #content {
    border: none;
  }

  @include media-breakpoint-up(sm) {
    .jumbotron {
      background-image: url('/plugins/arUnogPlugin/images/jumbotron.jpg');
      background-position: center 70%;
      background-size: cover;
      background-repeat: no-repeat;
      h1 {
        color: $white;
        text-shadow: 1px 1px 2px $black;
      }
    }
  }

  .browse-menu, .browse-menu .list-group-item {
    font-weight: bold;
  }

  .browse-menu .list-group-item {
    border-left: none;
    border-right: none;
    text-transform: uppercase;
    color: $white;
  }

  .browse-menu .list-group-item-action:hover,
  .browse-menu .list-group-item-action:active,
  .browse-menu .list-group-item-action:focus {
    background-color: $white !important;
    color: $primary;
  }

  .sign-up {
    background-image: url('/plugins/arUnogPlugin/images/sign-up.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    a {
      background-color: rgba($unogGrey, 0.5);
    }
  }

  .spotlight {
    figure {
      position: relative;
    }
    @include media-breakpoint-up(sm) {
      figcaption {
        position: relative;
      }
    }
  }

  #unog-carousel {
    .slick-dotted.slick-slider {
      margin: 0;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    .slick-dots {
      position: relative;
      bottom: 0;
    }
    .slick-arrow {
      top: calc(100% - 12px);
    }
    .slick-autoplay-toggle-button {
      color: #fff;
      top: calc(100% - 42px);
    }
    /* Accessible slick adds 7px for the tallest slide */
    .col-md-4 {
      background-color: $unogGrey;
      margin-top: -7px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-bottom: 7px;
      }
    }
  }

}

footer {
  .footer-main ul li a {
    height: 45px;
    width: 45px;
  }

  .footer-ownership {
    width: 80%;
    margin: 0 auto;
    font-size: 0.75 * $spacer;

    a {
      color: $black;
      &:hover {
        color: $black;
        text-decoration: underline $primary;
      }
    }
  }
}

h1,
body.index #content h2,
body.index #content h2 button,
body.index #atom-digital-object-carousel .accordion-button {
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
}

#sidebar .accordion-button span {
  text-transform: uppercase;
}

#sidebar .card h2,
#sidebar .card h5,
.accordion-button,
.atom-section-header > * {
  position: relative;
  padding-bottom: $spacer * 1.5 !important;

  &::before {
    content: "";
    position: absolute;
    bottom: $spacer;
    width: $spacer * 3;
    border-top: $primary solid 3px;
  }
}

.accordion-button::after, .atom-section-header a::after {
  margin-top: $spacer * 0.5;
}
